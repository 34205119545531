import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const contactUsEmail = () => {
  window.open('mailto:contact@asmibo.com?subject=Asmibo Consulting Inquiry');
}

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `orange`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        display: "flex",
        margin: `auto`,
        width: '80%',
        padding: `1.45rem 1.0875rem`,
        flexDirection: "row",
      }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            padding: "0 10px",
            flex: "grow",
            flexGrow: "1"
          }}>
          Asmibo
        </Link>

        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            flex: "grow",
            flexGrow: "9",
            textAlign: "right",
          }} onClick={contactUsEmail}>
            <span style={{
              border: "solid 3px white",
              "padding": "5px"
            }}>
              Contact Us
            </span>
        </Link>

        {/* <Link
          to="/blog"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          Blog
        </Link> */}
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
