/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  if (title === null) {
    title = data.site.siteMetadata.title
  }

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignContent: "center",
      fontFamily: "Roboto, sans-serif"
    }}>
      <div style={{
        flex: "flex-grow",
        flexGrow: "1"
      }}>
        <Header siteTitle={title} />
      </div>
      <div
        style={{
          width: "100%",
          padding: `0 1.0875rem 1.45rem`,
          flex: "flex-grow",
          flexGrow: "8"
        }}>
        <main>{children}</main>
      </div>

      <div style={{
        flex: "flex-grow",
        flexGrow: "1",
        margin: "auto",
        maxWidth: 960,
      }}>
        <footer>
          © {new Date().getFullYear()} Asmibo Solutions LLC
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
}

export default Layout
